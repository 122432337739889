<template>
  <div>
    <v-card flat>
      <v-container>
        <v-row>
          <v-col cols="12" md="8">
            <v-toolbar flat color="teal " dark class="title">
              <v-toolbar-title>My Cart - ({{ cartProducts.length }})</v-toolbar-title>
            </v-toolbar>
            <v-card outlined min-height="250">
              <v-divider></v-divider>
              <cart-items
                v-if="cartProducts.length"
                :items="cartProducts"
                @removeQuantity="removeQuantity"
                @addQuantity="addQuantity"
                @removeCartItem="removeCartItem"
              ></cart-items>
              <v-card-text class="text-center" v-else>
                <div>Your Shopping Cart is empty</div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-toolbar flat dark color="teal" class="title">
              <v-toolbar-title>Price Details</v-toolbar-title>
            </v-toolbar>
            <v-card class="d-flex flex-column" min-height="250" outlined v-if="cartProducts.length">
              <v-divider></v-divider>

              <v-row align="stretch">
                <v-col cols="12" class="d-flex justify-between">
                  <v-card-text>No. of Products</v-card-text>
                  <v-card-text class="text-right">{{ cartProducts.length}}</v-card-text>
                </v-col>
                <v-divider></v-divider>

                <v-col cols="6">
                  <v-card-text class="title primary--text">
                    <div>Total Price</div>
                  </v-card-text>
                </v-col>
                <v-col cols="6">
                  <v-card-text class="title primary--text font-weight-bold">
                    <div class="text-right">{{ totalPrice | currency }}</div>
                  </v-card-text>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12"></v-col>
              </v-row>

              <v-btn block to="/checkout" dark large tile depressed color="teal">Checkout</v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import CartItems from "./components/CartItems";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    CartItems
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["cartProducts", "totalPrice"])
  },
  methods: {
    ...mapActions(["removeCartItem", "addQuantity", "removeQuantity"])
  }
};
</script>

<style></style>
