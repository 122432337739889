<template>
  <v-list flat three-line v-if="items.length">
    <template v-for="(item, index) in items">
      <v-list-item :key="item.id">
        <router-link :to="'/products/category/' + item.slug">
          <v-list-item-avatar tile size="70">
            <v-img :src="item.images[0]"></v-img>
          </v-list-item-avatar>
        </router-link>

        <v-list-item-content>
          <v-list-item-title v-text="item.name"></v-list-item-title>
          <v-list-item-subtitle>
            <v-row>
              <v-col cols="6" md="2">
                {{ "Price: " + item.price | currency({
                symbol: '',
                fractionCount: 0
                })}} " x " {{ item.quantity }}
              </v-col>
              <v-col cols="2" md="1">
                <v-btn small color="success" icon>
                  <v-icon @click="$emit('addQuantity', item)" color="success">add</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="2" md="1">
                <v-btn small color="red" icon>
                  <v-icon @click="$emit('removeQuantity', item)" color="error  ">remove</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn small icon>
            <v-icon @click="$emit('removeCartItem', item)" color="error">clear</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider v-if="index + 1 < items.length" :key="index"></v-divider>
    </template>
  </v-list>
</template>

<script>
export default {
  props: {
    items: Array
  }
};
</script>

<style></style>
